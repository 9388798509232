<template>
  <div>
    <b-card>
      <form
          class="w-100"
          @submit.prevent="form.id ? UpdatePortfolio(form.id) : savePortfolio()"
          ref="formReset"
      >
        <div class="row">
          <div class="col-xl-3">
            <div class="form-group">
              <label for="title">Title *</label>
              <Input
                  type="text"
                  v-model="form.title"
                  placeholder="Title"
                  id="title"
              />
              <has-error :form="form" field="title"></has-error>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label for="link">Link</label>
              <Input
                  type="text"
                  v-model="form.link"
                  placeholder="Link"
                  id="link"
              />
              <has-error :form="form" field="link"></has-error>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label for="starting_date">Starting Date *</label>
              <DatePicker
                  type="date"
                  v-model="form.starting_date"
                  placeholder="Select date"
                  class="w-100"
              />
              <has-error :form="form" field="starting_date"></has-error>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label for="ending_date">Ending Date</label>
              <DatePicker
                  type="date"
                  v-model="form.ending_date"
                  placeholder="Select date"
                  class="w-100"
              />
              <has-error :form="form" field="ending_date"></has-error>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label for="Short Description">Short Description</label>
              <Input
                  type="textarea"
                  v-model="form.short_description"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  placeholder="Short Description...."
              />
              <has-error :form="form" field="short_description"></has-error>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label for="description">Description</label>
              <Input
                  type="textarea"
                  v-model="form.description"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  placeholder="Description...."
              />
              <has-error :form="form" field="description"></has-error>
            </div>
          </div>
          <div class="col-xl-12" :class="form.id ? 'd-none' : ''">
            <div class="form-group">
              <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    @click="addImageField"
                    :disabled="form.images.length >= 5"
                >Add Image
                </b-button
                >
              </div>
              <label for="images">Images *</label>
              <div
                  v-for="(image, index) in form.images"
                  :key="index"
                  class="mt-1"
              >
                <div class="row mb-2">
                  <div class="col-xl-3 image-upload">
                    <b-form-file
                        type="file"
                        @change="handleImageChange(index, $event)"
                    />
                  </div>
                  <div class="col-xl-4">
                    <Input
                        type="text"
                        v-model="image.title"
                        placeholder="Image Title *"
                    />
                  </div>
                  <div class="col-xl-4">
                    <Input
                        type="textarea"
                        v-model="image.description"
                        placeholder="Image Description...."
                    />
                  </div>
                  <div class="col-xl-1 text-right">
                    <b-button
                        variant="danger"
                        @click="removeImage(index)"
                        :disabled="form.images.length == 1"
                    >
                      <feather-icon icon="XCircleIcon" size="18"/>
                    </b-button>
                  </div>
                </div>
                <has-error
                    :form="form"
                    :field="'images.' + index + '.file'"
                ></has-error>
              </div>
            </div>
          </div>
          <div class="col-xl-12 text-right">
            <b-button type="button" @click="PhotClear(true)" variant="danger">
              Clear
            </b-button>

            <b-button
                type="submit"
                variant="primary"
                :disabled="form.id ? null : formIsInvalid"
                class="ml-1"
            >
              {{ form.id ? "Update" : "Save" }}
            </b-button>
          </div>
        </div>
      </form>
    </b-card>

    <h2>Portfolio Project</h2>
    <div
        v-if="portfolioProjects.length"
        class="row mt-2 shadow rounded"
        v-for="(project, index) in portfolioProjects"
        :key="index"
    >
      <div class="col-xl-6 mb-2">
        <b-button
            variant="primary"
            size="sm"
            @click="AddphotoModal(project.id)"
            class="mt-2"
        >
          <feather-icon icon="EditIcon" size="18"
          />
        </b-button>
        <Modal
            v-model="photoModal"
            title="Add portfolio photo"
            :footer-hide="true"
            width="80%"
        >
          <form
              class="w-100 mb-5"
              @submit.prevent="
                photo.id ? photoUpdate(photo.id) : savePortfolioPhoto()
              "
          >
            <div class="form-group">
              <div class="row">
                <div class="col-xl-4 image-upload">
                  <b-form-file
                      type="file"
                      @change="handlePhotoChange($event)"
                  />
                </div>
                <div class="col-xl-4">
                  <Input
                      type="text"
                      v-model="photo.title"
                      placeholder="Image Title *"
                  />
                  <has-error :form="photo" :field="photo.title"></has-error>
                </div>
                <div class="col-xl-4">
                  <Input
                      type="textarea"
                      v-model="photo.description"
                      placeholder="Image Description...."
                  />
                </div>
              </div>
              <has-error :form="photo" :field="photo.description"></has-error>
            </div>
            <div class="col-xl-12 text-right">
              <b-button
                  type="button"
                  variant="danger"
                  class="mr-1"
                  @click="PhotClear"
              >
                Clear
              </b-button>
              <b-button type="submit" variant="primary">
                {{ photo.id ? "Update" : "Save" }}
              </b-button>
            </div>
          </form>
          <div class="row border-top">
            <div
                class="col-xl-6 mb-3 shadow gap-2"
                v-for="(photo, index) in portfolioPhotos"
                :key="index"
            >
              <div class="row align-items-center">
                <div class="col-xl-6 border-right">
                  <img :src="photo.image" alt="" height="300"/>
                </div>
                <div class="col-xl-6">
                  <p>
                    <b>{{ photo.title }}</b>
                  </p>
                  <div>
                    <b-button
                        variant="primary"
                        size="sm"
                        @click="photoEdit(photo.id)"
                    >
                      <feather-icon icon="EditIcon" size="18"
                      />
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="ml-1 bg-danger border-danger"
                        @click="photoDelete(photo.id)"
                    >
                      <feather-icon icon="TrashIcon" size="18"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div class="text-center">
          <b-carousel id="carousel-caption" controls indicators>
            <b-carousel-slide
                v-for="(image, index) in project.images"
                :key="index"
                class="card-img-overlay"
                :img-src="image.image"
                style="height: 420px"
            >
              <div>
                <h2 class="text-white">{{ image.title }}</h2>
                <p>{{ image.description }}</p>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
      <div class="col-xl-6 border-left">
        <div class="pt-3">
          <div class="d-flex justify-content-between">
            <p v-if="project.starting_date">
              Project Complete : ({{ project.starting_date }} -
              {{ project.ending_date ? project.ending_date : "Running" }})
            </p>
            <div>
              <b-button variant="primary" size="sm" @click="edit(project.id)"
              >
                <feather-icon icon="EditIcon" size="18"
                />
              </b-button>
              <b-button
                  variant="primary"
                  size="sm"
                  class="ml-1 bg-danger border-danger"
                  @click="Delete(project.id)"
              >
                <feather-icon icon="TrashIcon" size="18"
                />
              </b-button>
            </div>
          </div>
          <h3>
            <b>{{ project.title }}</b>
          </h3>
          <p>{{ project.short_description }}</p>
          <p>{{ project.description }}</p>
          <b-button
          ><a :href="project.link" class="text-decoration-none text-white"
          >
            <feather-icon icon="GlobeIcon" size="18"/>
          </a
          ></b-button>
        </div>
      </div>
    </div>

    <div v-if="!portfolioProjects.length" class="text-center">
      <h4>No data found!</h4>
    </div>

  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BFormFile,
  BCarouselSlide,
  BCarousel,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        title: null,
        link: null,
        starting_date: null,
        ending_date: null,
        short_description: null,
        description: null,
        images: [
          {
            title: null,
            description: null,
            image: null,
          },
        ],
      }),
      photo: new Form({
        portfolio_id: "",
        id: "",
        title: "",
        description: "",
        image: "",
      }),

      portfolioProjects: [],
      portfolioPhotos: [],
      photoModal: false,
    };
  },
  components: {
    BCard,
    BButton,
    HasError,
    BFormFile,
    BCarousel,
    BCarouselSlide,
  },
  computed: {
    formIsInvalid() {
      return (
          !this.form.title ||
          !this.form.starting_date ||
          this.form.images.some((image) => !image.image)
      );
    },
  },
  mounted() {
    this.getPortfolio();
  },

  methods: {
    handleImageChange(index, event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.images[index].image = e.target.result; // Set binary data
        };
        reader.readAsDataURL(file);
      }
    },

    addImageField() {
      this.form.images.push({
        image: null,
        title: "",
        description: "",
      });
    },

    removeImage(index) {
      this.form.images.splice(index, 1);
    },

    getPortfolio() {
      axios
          .get("/candidate/portfolio")
          .then((res) => {
            this.portfolioProjects = res.data.data;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },

    savePortfolio() {
      if (!this.formIsInvalid) {
        this.form
            .post("/candidate/portfolio/")
            .then((res) => {
              this.s(res.data.message);
              this.$refs.formReset.reset();
              this.getPortfolio();
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
      }
    },

    edit(id) {
      axios
          .get("/candidate/portfolio/" + id)
          .then((res) => {
            let portfolio = res.data.portfolio;
            this.form.id = portfolio.id;
            this.form.title = portfolio.title;
            this.form.link = portfolio.link;
            this.form.starting_date = portfolio.starting_date;
            this.form.ending_date = portfolio.ending_date;
            this.form.short_description = portfolio.short_description;
            this.form.description = portfolio.description;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    UpdatePortfolio(id) {
      this.form
          .put("/candidate/portfolio/" + id)
          .then((res) => {
            this.s(res.data.message);
            this.$refs.formReset.reset();
            this.form.id = null;
            this.getPortfolio();
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .delete("/candidate/portfolio/" + id)
              .then((res) => {
                this.s(res.data.message);
                this.getPortfolio();
              })
              .catch((e) => {
                this.e(e.response.data.message);
              });
        }
      });
    },

    AddphotoModal(portfolioId) {
      this.photoModal = true;
      this.photo.portfolio_id = portfolioId;
      this.getPortfolioPhoto();
    },
    handlePhotoChange(e) {
      this.photo.image = e.target.files[0];
    },

    PhotClear(mainForm) {
      if (mainForm) {
        this.$refs.formReset.reset();
        return;
      }
      this.photo.id = "";
      this.photo.title = "";
      this.photo.description = "";
    },

    savePortfolioPhoto() {
      this.photo
          .post("/candidate/portfolio/image")
          .then((res) => {
            this.s(res.data.message);
            this.getPortfolioPhoto();
            this.getPortfolio();
            this.PhotClear();
          })
          .catch((e) => {
            if (e.response.data.message) {
              this.e(e.response.data.message);
            } else {
              this.e(e.response.data.error);
            }
          });
    },

    getPortfolioPhoto() {
      axios
          .get("/candidate/portfolio/all/image/" + this.photo.portfolio_id)
          .then((res) => {
            this.portfolioPhotos = res.data.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    photoEdit(id) {
      axios
          .get("/candidate/portfolio/image/" + id)
          .then((res) => {
            let photo = res.data.data;
            this.photo.id = photo.id;
            this.photo.title = photo.title;
            this.photo.description = photo.description;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    photoUpdate(id) {
      this.photo
          .put("/candidate/portfolio/image/" + id)
          .then((res) => {
            this.s(res.data.message);
            this.getPortfolioPhoto();
            this.getPortfolio();
            this.PhotClear();
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    photoDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .delete("/candidate/portfolio/image/" + id)
              .then((res) => {
                this.s(res.data.message);
                this.getPortfolioPhoto();
                this.getPortfolio();
              })
              .catch((e) => {
                this.e(e.response.data.message);
              });
        }
      });
    },
  },
};
</script>
<style scoped>


.card-img-overlay {
  position: relative;
}

.card-img-overlay::after {
  position: absolute;
  content: "";
  top: 4%;
  left: 2.5%;
  width: 95%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
</style>
